import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import { Footer, Sidebar, Topbar } from "./components";

const Main = () => {
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });

  const shouldOpenSidebar = isDesktop ? true : false;

  const [openSidebar, setOpenSidebar] = useState(shouldOpenSidebar);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  return (
    <div
      style={{
        paddingTop: "64px",
        height: "calc(100vh - 64px)",
        ...(openSidebar && {
          paddingLeft: 240,
        }),
        ...(!openSidebar && {
          paddingLeft: 70,
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }),
      }}
    >
      <Topbar />

      <Sidebar open={openSidebar} onOpen={handleSidebarOpen} onClose={handleSidebarClose} variant="permanent" />

      <main
        style={{
          overflow: "auto",
          height: "calc(100vh - 64px - 100px)",
          backgroundColor: "#f9f9f9",
          padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(7)} ${theme.spacing(2)}`,
        }}
      >
        <Outlet />
      </main>

      <Footer />
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;
