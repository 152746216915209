import React, { lazy, useContext } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import AdminRoutes from "./AdminRoutes";
import Loadable from "../components/loadable/Loadable";
import { AuthContext, EntityContext } from "../App";

const Login = Loadable(lazy(() => import("../modules/login/Login")));
const Register = Loadable(lazy(() => import("../modules/register/Register")));
const TemplatePreview = Loadable(lazy(() => import("../modules/template/TemplatePreview")));

const ProtectedRoute = ({ children }) => {
  const { authState } = useContext(AuthContext);

  if (!authState.isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default function ThemeRoutes() {
  const { can } = useContext(EntityContext);

  return useRoutes(
    [
      AdminRoutes(can),
      {
        path: "/admin/templates/preview",
        element: (
          <ProtectedRoute>
            <TemplatePreview />
          </ProtectedRoute>
        ),
      },
      {
        path: "/",
        element: <Navigate to="/admin/dashboard" replace />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/register",
        element: <Register />,
      },
    ],
    ""
  );
}
