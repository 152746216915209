import React, { useState, useEffect } from "react";
import Badge from "@mui/material/Badge";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNotificationService } from "./useNotificationService";
import { formatDistance } from "date-fns";

const Notification = (props) => {
  const { findAll, markAsRead, destroy } = useNotificationService();

  const [refresh, setRefresh] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (refresh) {
      findAll()
        .then((res) => {
          if (res.status === "success") {
            setData(res.data);
          }
        })
        .finally(() => {
          setRefresh(false);
        });
    }
  }, [refresh]);

  const onMarkAsRead = (item) => {
    markAsRead(item._id).then((res) => {
      if (res.status === "success") {
        setRefresh(true);
      }
    });
  };

  const onRemove = (item) => {
    destroy(item._id).then((res) => {
      if (res.status === "success") {
        setRefresh(true);
      }
    });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card elevation={0}>
          <CardContent>
            <List>
              {data.map((item) => (
                <ListItemButton onClick={() => onMarkAsRead(item)}>
                  {!item["Notification/IsRead"] && (
                    <Badge
                      color="primary"
                      variant="dot"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      sx={{ mr: 2 }}
                    />
                  )}
                  <ListItemText primary={item["Notification/Title"]} secondary={item["Notification/Description"]} />
                  <Stack direction="row" alignItems={"center"} spacing={1}>
                    <Typography variant="caption" color="text.secondary">
                      {formatDistance(new Date(item["Notification/Instant"]), new Date(), { addSuffix: true })}
                    </Typography>
                    <IconButton onClick={() => onRemove(item)}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Stack>
                </ListItemButton>
              ))}
            </List>
            <Stack direction={"row"} justifyContent={"end"}>
              <Pagination count={10} variant="outlined" shape="rounded" />
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Notification;
