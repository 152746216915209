import { useCallback } from "react";
import { httpFetch } from "../../services/SecurityApiService";
import { fp } from "../../App";

const useLoginService = () => {
  const login = useCallback(async (params) => {
    const config = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        FpId: (await fp).visitorId,
      },
      body: JSON.stringify(params),
      credentials: "include",
    };

    return await httpFetch("/login", config);
  }, []);

  const logout = useCallback(async (token) => {
    const config = {
      method: "post",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        FpId: (await fp).visitorId,
      },
    };

    return await httpFetch("/logout", config);
  }, []);

  const checkAuth = useCallback(async (params) => {
    const config = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        FpId: (await fp).visitorId,
      },
      body: JSON.stringify(params),
      credentials: "include",
    };

    return await httpFetch("/auth", config);
  }, []);

  const updatePassword = useCallback(async (token, params) => {
    const config = {
      method: "post",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        FpId: (await fp).visitorId,
      },
      body: JSON.stringify(params),
    };
    return await httpFetch("/auth/profile/securitySettings/updatePassword", config);
  });

  const checkPassword = useCallback(async (token, params) => {
    const config = {
      method: "post",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        FpId: (await fp).visitorId,
      },
      body: JSON.stringify(params),
    };
    return await httpFetch("/auth/checkPassword", config);
  });

  return {
    login,
    logout,
    checkAuth,
    updatePassword,
    checkPassword,
  };
};

export { useLoginService };
