import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React, { lazy, useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../App";
import Loadable from "../components/loadable/Loadable";
import MainLayout from "../layouts/Main";
import Monitor from "../modules/monitor/Monitor";
import Notification from "../modules/notification/Notification";

const Dashboard = Loadable(lazy(() => import("../modules/dashboard/Dashboard")));

const Product = Loadable(lazy(() => import("../modules/product/Product")));
const NewProduct = Loadable(lazy(() => import("../modules/product/NewProduct")));
const ProductDetail = Loadable(lazy(() => import("../modules/product/ProductDetail")));

const Order = Loadable(lazy(() => import("../modules/order/Order")));
const NewOrder = Loadable(lazy(() => import("../modules/order/NewOrder")));
const OrderDetail = Loadable(lazy(() => import("../modules/order/OrderDetail")));

const Production = Loadable(lazy(() => import("../modules/production/Production")));
const ProductionDetail = Loadable(lazy(() => import("../modules/production/ProductionDetail")));

const QRDesign = Loadable(lazy(() => import("../modules/qrDesign/QRDesign")));
const NewQRDesign = Loadable(lazy(() => import("../modules/qrDesign/NewQRDesign")));
const QRDesignEditor = Loadable(lazy(() => import("../modules/qrDesign/QRDesignEditor")));

const Template = Loadable(lazy(() => import("../modules/template/Template")));
const TemplateEditor = Loadable(lazy(() => import("../modules/template/TemplateEditor")));

const Profile = Loadable(lazy(() => import("../modules/profile/Profile")));

const RFID = Loadable(lazy(() => import("../modules/rfid/Rfid")));
const RFIDDetail = Loadable(lazy(() => import("../modules/rfid/RfidDetail")));

const Supplier = Loadable(lazy(() => import("../modules/supplier/Supplier")));
const NewSupplier = Loadable(lazy(() => import("../modules/supplier/NewSupplier")));
const SupplierDetail = Loadable(lazy(() => import("../modules/supplier/SupplierDetail")));

const Customer = Loadable(lazy(() => import("../modules/customer/Customer")));
const NewCustomer = Loadable(lazy(() => import("../modules/customer/NewCustomer")));
const CustomerDetail = Loadable(lazy(() => import("../modules/customer/CustomerDetail")));

const RawMaterial = Loadable(lazy(() => import("../modules/rawMaterial/RawMaterial")));
const NewRawMaterial = Loadable(lazy(() => import("../modules/rawMaterial/NewRawMaterial")));
const RawMaterialDetail = Loadable(lazy(() => import("../modules/rawMaterial/RawMaterialDetail")));

const UOM = Loadable(lazy(() => import("../modules/uom/Uom")));
const NewUOM = Loadable(lazy(() => import("../modules/uom/NewUom")));

const StockIn = Loadable(lazy(() => import("../modules/stockIn/StockIn")));
const NewStockIn = Loadable(lazy(() => import("../modules/stockIn/NewStockIn")));

const Disposal = Loadable(lazy(() => import("../modules/disposal/Disposal")));
const NewDisposal = Loadable(lazy(() => import("../modules/disposal/NewDisposal")));
const DisposalDetail = Loadable(lazy(() => import("../modules/disposal/DisposalDetail")));

const Usage = Loadable(lazy(() => import("../modules/usage/Usage")));
const NewUsage = Loadable(lazy(() => import("../modules/usage/NewUsage")));
const UsageDetail = Loadable(lazy(() => import("../modules/usage/UsageDetail")));

const Sale = Loadable(lazy(() => import("../modules/sale/Sale")));
const NewSale = Loadable(lazy(() => import("../modules/sale/NewSale")));
const SaleDetail = Loadable(lazy(() => import("../modules/sale/SaleDetail")));

// // Sinisana
// const Security = Loadable(lazy(() => import("../views/sinisana/security/Security")));
// const SecurityDetail = Loadable(lazy(() => import("../views/sinisana/security/SecurityDetail")));
const Entity = Loadable(lazy(() => import("../modules/entity/Entity")));
const EntityDetail = Loadable(lazy(() => import("../modules/entity/EntityDetail")));
const User = Loadable(lazy(() => import("../modules/user/User")));
const UserDetail = Loadable(lazy(() => import("../modules/user/UserDetail")));

const PermissionDenied = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box>
          <h1>Permission Denied</h1>
          <p>You don't have permission to access this page. Please contact your administrator.</p>
        </Box>
      </Grid>
    </Grid>
  );
};

const ProtectedRoute = ({ children }) => {
  const { authState } = useContext(AuthContext);

  if (!authState.isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

// ==============================|| MAIN ROUTING ||============================== //
const AdminRoutes = (can) => {
  return {
    path: "/admin",
    element: (
      <ProtectedRoute>
        <MainLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "monitor",
        element: can("Monitor") ? <Monitor /> : <PermissionDenied />,
      },
      {
        path: "dashboard",
        element: can("Dashboard") ? <Dashboard /> : <PermissionDenied />,
      },
      {
        path: "products",
        element: can("Product.View") ? <Product /> : <PermissionDenied />,
      },
      {
        path: "products/create",
        element: can("Product.Create") ? <NewProduct /> : <PermissionDenied />,
      },
      {
        path: "products/:id",
        element: can("Product.View") ? <ProductDetail /> : <PermissionDenied />,
      },
      {
        path: "orders",
        element: can("Order.View") ? <Order /> : <PermissionDenied />,
      },
      {
        path: "orders/create",
        element: can("Order.Create") ? <NewOrder /> : <PermissionDenied />,
      },
      {
        path: "orders/:id",
        element: can("Order.View") ? <OrderDetail /> : <PermissionDenied />,
      },
      {
        path: "productions",
        element: can("Production.View") ? <Production /> : <PermissionDenied />,
      },
      {
        path: "productions/:id",
        element: can("Production.View") ? <ProductionDetail /> : <PermissionDenied />,
      },
      {
        path: "qrDesigns",
        element: can("QRDesign.View") ? <QRDesign /> : <PermissionDenied />,
      },
      {
        path: "qrDesigns/create",
        element: can("QRDesign.Create") ? <NewQRDesign /> : <PermissionDenied />,
      },
      {
        path: "qrDesigns/:id/editor",
        element: can("QRDesign.Create") ? <QRDesignEditor /> : <PermissionDenied />,
      },
      {
        path: "templates",
        element: can("Template.View") ? <Template /> : <PermissionDenied />,
      },
      {
        path: "templates/:id/editor",
        element: can("Template.Create") ? <TemplateEditor /> : <PermissionDenied />,
      },
      {
        path: "users",
        element: can("User") ? <User /> : <PermissionDenied />,
      },
      {
        path: "users/:id",
        element: can("User") ? <UserDetail /> : <PermissionDenied />,
      },
      {
        path: "entities",
        element: can("Entity") ? <Entity /> : <PermissionDenied />,
      },
      {
        path: "entities/:id",
        element: can("Entity") ? <EntityDetail /> : <PermissionDenied />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "notifications",
        element: <Notification />,
      },
      {
        path: "stockIn",
        element: can("StockIn.View") ? <StockIn /> : <PermissionDenied />,
      },
      {
        path: "stockIn/create",
        element: can("StockIn.Create") ? <NewStockIn /> : <PermissionDenied />,
      },
      {
        path: "disposal",
        element: can("Disposal.View") ? <Disposal /> : <PermissionDenied />,
      },
      {
        path: "disposal/create",
        element: can("Disposal.Create") ? <NewDisposal /> : <PermissionDenied />,
      },
      {
        path: "disposal/:id",
        element: can("Disposal.View") ? <DisposalDetail /> : <PermissionDenied />,
      },
      {
        path: "usage",
        element: can("Usage.View") ? <Usage /> : <PermissionDenied />,
      },
      {
        path: "usage/create",
        element: can("Usage.Create") ? <NewUsage /> : <PermissionDenied />,
      },
      {
        path: "usage/:id",
        element: can("Usage.View") ? <UsageDetail /> : <PermissionDenied />,
      },
      {
        path: "sale",
        element: can("Sale.View") ? <Sale /> : <PermissionDenied />,
      },
      {
        path: "sale/create",
        element: can("Sale.Create") ? <NewSale /> : <PermissionDenied />,
      },
      {
        path: "sale/:id",
        element: can("Sale.View") ? <SaleDetail /> : <PermissionDenied />,
      },
      {
        path: "rfid",
        element: can("RFID.View") ? <RFID /> : <PermissionDenied />,
      },
      {
        path: "rfid/:id",
        element: can("RFID.View") ? <RFIDDetail /> : <PermissionDenied />,
      },
      {
        path: "suppliers",
        element: can("Supplier.View") ? <Supplier /> : <PermissionDenied />,
      },
      {
        path: "suppliers/create",
        element: can("Supplier.Create") ? <NewSupplier /> : <PermissionDenied />,
      },
      {
        path: "suppliers/:id",
        element: can("Supplier.View") ? <SupplierDetail /> : <PermissionDenied />,
      },
      {
        path: "customers",
        element: can("Customer.View") ? <Customer /> : <PermissionDenied />,
      },
      {
        path: "customers/create",
        element: can("Customer.Create") ? <NewCustomer /> : <PermissionDenied />,
      },
      {
        path: "customers/:id",
        element: can("Customer.View") ? <CustomerDetail /> : <PermissionDenied />,
      },
      {
        path: "rawMaterials",
        element: can("RawMaterial.View") ? <RawMaterial /> : <PermissionDenied />,
      },
      {
        path: "rawMaterials/create",
        element: can("RawMaterial.Create") ? <NewRawMaterial /> : <PermissionDenied />,
      },
      {
        path: "rawMaterials/:id",
        element: can("RawMaterial.View") ? <RawMaterialDetail /> : <PermissionDenied />,
      },
      {
        path: "uom",
        element: can("UOM.View") ? <UOM /> : <PermissionDenied />,
      },
      {
        path: "uom/create",
        element: can("UOM.Create") ? <NewUOM /> : <PermissionDenied />,
      },
      /* {
        path: "rawMaterials/:id",
        element: can("RawMaterial.View") ? <RawMaterialDetail /> : <PermissionDenied />,
      }, */
    ],
  };
};

export default AdminRoutes;
