/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef, useContext } from "react";
import { NavLink as RouterLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import * as colors from "@mui/material/colors";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import { EntityContext } from "../../../../../../App";

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = (props) => {
  const pathname = window.location.pathname;

  const { pages, open } = props;

  const theme = useTheme();
  const navigate = useNavigate();

  const { can } = useContext(EntityContext);

  return (
    <List>
      {pages
        .filter((page) => (page.permission ? can(page.permission) : true))
        .map((page) => (
          <ListItem disablePadding key={page.title}>
            <ListItemButton
              key={page.title}
              selected={page.href === pathname}
              sx={{
                color: page.href === pathname ? theme.palette.primary.main : colors.blueGrey[800],
                backgroundColor: page.href === pathname ? theme.palette.primary.light : null,
                fontWeight: theme.typography.fontWeightMedium,
                padding: 1,
                margin: open ? 0.5 : 0,
              }}
              disableGutters
              to={page.href}
              component={CustomRouterLink}
            >
              <Tooltip title={page.title}>
                <ListItemIcon
                  sx={{
                    color: page.href === pathname ? theme.palette.primary.main : theme.palette.icon,
                  }}
                >
                  {page.icon}
                </ListItemIcon>
              </Tooltip>
              <ListItemText disableTypography primary={page.title} />
            </ListItemButton>
          </ListItem>
        ))}
    </List>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.array.isRequired,
};

export default SidebarNav;
