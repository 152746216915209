import { useCallback, useContext } from "react";
import { httpFetch } from "../../services/AdminApiService";
import { AuthContext, fp } from "../../App";

const useMyEntityService = () => {
  const { authState } = useContext(AuthContext);

  const myEntity = useCallback(
    async (id) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await httpFetch("/myEntity/" + id, config);
    },
    [authState]
  );

  const update = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };
      return await httpFetch("/myEntity/" + id, config);
    },
    [authState]
  );

  const uploadMyEntityLogo = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          FpId: (await fp).visitorId,
        },
        body: params,
      };

      return await httpFetch("/myEntity/" + id + "/uploadMyEntityLogo", config);
    },
    [authState]
  );

  const removeMyEntityLogo = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await httpFetch("/myEntity/" + id + "/removeMyEntityLogo", config);
    },
    [authState]
  );

  const uploadMyEntityDocument = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          FpId: (await fp).visitorId,
        },
        body: params,
      };

      return await httpFetch("/myEntity/" + id + "/uploadMyEntityDocument", config);
    },
    [authState]
  );

  const removeMyEntityDocument = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await httpFetch("/myEntity/" + id + "/removeMyEntityDocument", config);
    },
    [authState]
  );

  const upgradeToVerifiedPro = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          FpId: (await fp).visitorId,
        },
        body: params,
      };

      return await httpFetch("/myEntity/" + id + "/upgradeToVerifiedPro", config);
    },

    [authState]
  );

  return {
    myEntity,
    update,
    uploadMyEntityLogo,
    removeMyEntityLogo,
    uploadMyEntityDocument,
    removeMyEntityDocument,
    upgradeToVerifiedPro,
  };
};

export { useMyEntityService };
