import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

const Footer = () => {
  const theme = useTheme();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "36px",
        paddingLeft: theme.spacing(2),
        justifyContent: "center",
        backgroundColor: theme.palette.background.paper,
        position: "fixed",
        bottom: 0,
        width: "100%",
      }}
    >
      <Typography variant="caption" display="block">
        Copyright Sinisana Technologies Sdn. Bhd.
      </Typography>
    </div>
  );
};

Footer.propTypes = {};

export default Footer;
