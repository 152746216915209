import React, { useState, useEffect, useContext, useReducer } from "react";
import { ThemeProvider } from "@mui/material/styles";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import theme from "./theme";
import ThemeRoutes from "./routes/index";
import { BrowserRouter } from "react-router-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { useLoginService } from "./modules/login/useLoginService";
import { useMyAccountService } from "./modules/myAccount/useMyAccountService";
import PageLoader from "./components/loadable/PageLoader";

export const fp = (async () => {
  const fp = await FingerprintJS.load();
  return await fp.get();
})();

export const AuthContext = React.createContext();
export const EntityContext = React.createContext();

const initialState = {
  token: null,
  authUser: null,
  isAuthenticated: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        authUser: action.payload.authUser,
        token: action.payload.token,
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        authUser: null,
        token: null,
        isLogOut: true,
      };
    case "UPDATE":
      return {
        ...state,
        authUser: action.payload.authUser,
      };
    default:
      return state;
  }
};

const App = () => {
  const { checkAuth } = useLoginService();

  const [authState, dispatch] = useReducer(reducer, initialState);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkAuth()
      .then((res) => {
        if (res.status === "success") {
          dispatch({
            type: "LOGIN",
            payload: {
              authUser: res.data.auth,
              token: res.data.token,
            },
          });
        } else {
          dispatch({ type: "LOGOUT" });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authState,
        dispatch,
      }}
    >
      {loading ? (
        <PageLoader />
      ) : (
        <Entity>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <BrowserRouter>
                <ThemeRoutes />
              </BrowserRouter>
            </ThemeProvider>
          </StyledEngineProvider>
        </Entity>
      )}
    </AuthContext.Provider>
  );
};

const Entity = (props) => {
  const { authState } = useContext(AuthContext);

  const { myAccount, findPermission } = useMyAccountService();

  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState({});
  const [activeEntity, setActiveEntity] = useState({});
  const [entityList, setEntityList] = useState([]);
  const [authPermissions, setAuthPermissions] = useState([]);

  useEffect(() => {
    if (authState && loading) {
      myAccount()
        .then((res) => {
          if (res.status === "success") {
            setAccount(res.data);

            if (res.data["Entity/_Users"] && res.data["Entity/_Users"].length > 0) {
              setEntityList(res.data["Entity/_Users"]);
              setActiveEntity(res.data["Entity/_Users"][0]);
            }
          } else {
            setActiveEntity({});
          }
        })
        .finally(() => setLoading(false));
    }
  }, [loading, authState]);

  useEffect(() => {
    if (authState.isAuthenticated) {
      myAccount().then((res) => {
        if (res.status === "success") {
          setAccount(res.data);

          if (res.data["Entity/_Users"] && res.data["Entity/_Users"].length > 0) {
            setEntityList(res.data["Entity/_Users"]);
            setActiveEntity(res.data["Entity/_Users"][0]);
          }
        } else {
          setActiveEntity(null);
        }
      });
    }
  }, [authState]);

  useEffect(() => {
    if (activeEntity && activeEntity._id && account && account._id) {
      findPermission({
        entityId: activeEntity._id,
        accountId: account._id,
      }).then((res) => {
        if (res.status === "success") {
          setAuthPermissions(res.data);
        }
      });
    }
  }, [activeEntity, account]);

  const can = (route) => {
    if (authPermissions.includes(route)) {
      return true;
    }
    return false;
  };

  return (
    <EntityContext.Provider value={{ can, account, activeEntity, setActiveEntity, entityList, authPermissions }}>
      {loading ? <PageLoader /> : props.children}
    </EntityContext.Provider>
  );
};

export default App;
