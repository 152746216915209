import { useCallback, useContext } from "react";
import { httpFetch } from "../../services/AdminApiService";
import { AuthContext, fp } from "../../App";

const useMyAccountService = () => {
  const { authState } = useContext(AuthContext);

  const myAccount = useCallback(async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: "Bearer " + authState.token,
        "Content-Type": "application/json",
        FpId: (await fp).visitorId,
      },
    };

    return await httpFetch("/myAccount", config);
  }, [authState]);

  const myCompany = useCallback(
    async (id) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await httpFetch("/myAccount/" + id, config);
    },
    [authState]
  );

  const findPermission = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await httpFetch("/myAccount/findPermission", config);
    },
    [authState]
  );

  const updateMyAccount = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };
      return await httpFetch("/myAccount/" + id + "/updateMyAccount", config);
    },
    [authState]
  );

  const updateEntityInfo = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };
      return await httpFetch("/myAccount/" + id + "/updateEntityInfo", config);
    },
    [authState]
  );

  const uploadEntityLogo = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          FpId: (await fp).visitorId,
        },
        body: params,
      };

      return await httpFetch("/myAccount/" + id + "/uploadEntityLogo", config);
    },
    [authState]
  );

  const removeEntityLogo = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await httpFetch("/myAccount/" + id + "/removeEntityLogo", config);
    },
    [authState]
  );

  const uploadEntityDocument = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          FpId: (await fp).visitorId,
        },
        body: params,
      };

      return await httpFetch("/myAccount/" + id + "/uploadEntityDocument", config);
    },
    [authState]
  );

  return {
    myAccount,
    myCompany,
    findPermission,
    updateMyAccount,
    updateEntityInfo,
    uploadEntityLogo,
    removeEntityLogo,
    uploadEntityDocument,
  };
};

export { useMyAccountService };
