import { useCallback, useContext } from "react";
import { httpFetch } from "../../services/AdminApiService";
import { AuthContext, EntityContext, fp } from "../../App";

const useNotificationService = () => {
  const { authState } = useContext(AuthContext);
  const { activeEntity } = useContext(EntityContext);

  const findAll = useCallback(
    async (query) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await httpFetch("/notifications?activeEntityId=" + activeEntity._id, config);
    },
    [authState, activeEntity]
  );

  const markAsRead = useCallback(
    async (id) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await httpFetch("/notifications/" + id + "/markAsRead", config);
    },
    [authState]
  );

  const destroy = useCallback(
    async (id) => {
      const config = {
        method: "delete",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await httpFetch("/notifications/" + id, config);
    },
    [authState]
  );

  const findTotalUnread = useCallback(
    async (query) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await httpFetch("/notifications/findTotalUnread?activeEntityId=" + activeEntity._id, config);
    },
    [authState, activeEntity]
  );

  return {
    findAll,
    markAsRead,
    destroy,
    findTotalUnread,
  };
};

export { useNotificationService };
