import React, { useState, useContext, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import InputIcon from "@mui/icons-material/Input";
import NotificationsIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { AuthContext, EntityContext } from "../../../../App";
import { BaseAPIUrl } from "../../../../services/WebApiService";
import { useLoginService } from "../../../../modules/login/useLoginService";
import { useMyEntityService } from "../../../../modules/profile/useMyEntityService";
import { EntityStatusColor } from "../../../../constants/Utils";
import IndividualDialog from "../../../../modules/kyc/individual/IndividualDialog";
import SoleProDialog from "../../../../modules/kyc/solepro/SoleProDialog";
import PrivateLimitedDialog from "../../../../modules/kyc/pvtltd/PrivateLimitedDialog";
import CooperativeDialog from "../../../../modules/kyc/cooperative/CooperativeDialog";
import { useNotificationService } from "../../../../modules/notification/useNotificationService";

const Topbar = (props) => {
  const navigate = useNavigate();

  const { authState, dispatch } = useContext(AuthContext);

  const { account, activeEntity } = useContext(EntityContext);

  const { upgradeToVerifiedPro } = useMyEntityService();
  const { findTotalUnread } = useNotificationService();
  const { logout } = useLoginService();

  const [totalUnread, setTotalUnread] = useState(0);
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [verifyProIndividual, setVerifyProIndividual] = useState(false);
  const [verifyProSoleProprietorship, setVerifyProSoleProprietorship] = useState(false);
  const [verifyProPrivateLimited, setVerifyProPrivateLimited] = useState(false);
  const [verifyProCooperative, setVerifyProCooperative] = useState(false);

  useEffect(() => {
    if (activeEntity["Entity/Logo"]) {
      setCompanyLogo(JSON.parse(activeEntity["Entity/Logo"]));
    }

    findTotalUnread().then((res) => {
      if (res.status === "success") {
        setTotalUnread(res.data);
      }
    });
  }, [activeEntity]);

  const signOut = () => {
    logout(authState.token);

    dispatch({
      type: "LOGOUT",
    });
  };

  const handleClose = () => {
    setOpenMenu(false);
  };

  const onVerifyPro = () => {
    let businessRegType = activeEntity["Entity/RegType"];

    if (businessRegType === "Individual") {
      setVerifyProIndividual(true);
    } else if (businessRegType === "Sole Proprietorship/Partnership") {
      setVerifyProSoleProprietorship(true);
    } else if (businessRegType === "Private Limited Company") {
      setVerifyProPrivateLimited(true);
    } else if (businessRegType === "Co-operative") {
      setVerifyProCooperative(true);
    } else {
      alert("Please select a business registration type under Settings > Company");
    }
  };

  const onSubmit = async (params) => {
    const res = await upgradeToVerifiedPro(activeEntity["_id"], params);

    if (res.status === "success") {
      setVerifyProIndividual(false);
      setVerifyProSoleProprietorship(false);
      setVerifyProPrivateLimited(false);
      setVerifyProCooperative(false);
      alert(
        "Your request has been submitted successfully. It will take 3-5 working days to process. Our team may contact you for further information if needed."
      );
      return true;
    }
    return false;
  };

  return (
    <AppBar
      sx={{
        boxShadow: "none",
        backgroundColor: "#9D1B2B",
      }}
    >
      <Toolbar>
        <Stack direction={"row"} flexGrow={1} justifyContent={"space-between"} alignItems={"center"}>
          <RouterLink to="/">
            <img alt="Logo" src="/images/logo-title.png" width="182" height="40" />
          </RouterLink>
          <Box>
            <Hidden mdDown>
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <IconButton color="inherit" onClick={() => navigate("/admin/notifications")}>
                  <Badge badgeContent={totalUnread > 0 ? totalUnread : null} color="primary">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>

                <IconButton
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                    setOpenMenu(true);
                  }}
                >
                  <Badge
                    variant="dot"
                    color={EntityStatusColor[activeEntity["Entity/Status"]]}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    overlap="circular"
                  >
                    <Avatar
                      sx={{ height: "32px", width: "32px", backgroundColor: "#2B2A4C" }}
                      alt={account["_user/Name"] ? account["_user/Name"] : ""}
                    >
                      {account["_user/Name"]?.charAt(0)}
                    </Avatar>
                  </Badge>
                </IconButton>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    horizontal: "right",
                  }}
                >
                  <Stack direction={"row"} p={2} alignItems={"center"} spacing={2}>
                    <Badge
                      variant="dot"
                      color={EntityStatusColor[activeEntity["Entity/Status"]]}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      overlap="circular"
                    >
                      <Avatar
                        sx={{ height: "48px", width: "48px", backgroundColor: "#2B2A4C" }}
                        alt={account["_user/Name"]}
                        src={BaseAPIUrl + "/images/" + companyLogo?.key}
                      >
                        {activeEntity["Entity/Name"]?.charAt(0)}
                      </Avatar>
                    </Badge>

                    <Stack direction={"column"} spacing={1}>
                      <Typography variant="v6">{activeEntity["Entity/Name"]}</Typography>
                      <Typography variant="caption">{activeEntity["Entity/Status"]}</Typography>
                    </Stack>
                  </Stack>

                  {!activeEntity["Entity/Status"] || activeEntity["Entity/Status"] === "Not Verified" ? (
                    <Box mb={2} textAlign={"center"}>
                      <Link component={"button"} onClick={onVerifyPro} sx={{ cursor: "pointer" }}>
                        Go Verified PRO
                      </Link>
                    </Box>
                  ) : null}

                  <Divider />

                  <MenuItem
                    onClick={() => {
                      handleClose();
                      return navigate(`/admin/profile`);
                    }}
                  >
                    <Stack direction={"row"} spacing={4} justifyContent={"space-between"} alignItems={"center"}>
                      <SettingsOutlinedIcon />
                      <Typography>Settings</Typography>
                    </Stack>
                  </MenuItem>
                  <Divider />

                  <MenuItem onClick={signOut}>
                    <Stack direction={"row"} spacing={4} justifyContent={"space-between"} alignItems={"center"}>
                      <InputIcon />
                      <Typography>Sign Out</Typography>
                    </Stack>
                  </MenuItem>
                </Menu>
              </Stack>
            </Hidden>
          </Box>
        </Stack>

        <IndividualDialog
          visible={verifyProIndividual}
          onClose={() => setVerifyProIndividual(false)}
          onSubmit={onSubmit}
        />

        <SoleProDialog
          visible={verifyProSoleProprietorship}
          onClose={() => setVerifyProSoleProprietorship(false)}
          onSubmit={onSubmit}
        />

        <PrivateLimitedDialog
          visible={verifyProPrivateLimited}
          onClose={() => setVerifyProPrivateLimited(false)}
          onSubmit={onSubmit}
        />

        <CooperativeDialog
          visible={verifyProCooperative}
          onClose={() => setVerifyProCooperative(false)}
          onSubmit={onSubmit}
        />
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
