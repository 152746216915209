export const EntityStatusColor = {
  "Not Verified": "default",
  "Pending Verification": "warning",
  Active: "success",
};

export const CompanyRegType = [
  "Individual",
  "Sole Proprietorship/Partnership",
  "Private Limited Company",
  "Co-operative",
];

export const StateInMalaysia = [
  "Johor",
  "Kedah",
  "Kelantan",
  "Kuala Lumpur",
  "Labuan",
  "Melaka",
  "Negeri Sembilan",
  "Pahang",
  "Penang",
  "Perak",
  "Perlis",
  "Putrajaya",
  "Sabah",
  "Sarawak",
  "Selangor",
  "Terengganu",
];

export const BusinessIndustry = [
  "Automotive",
  "Baby and Kids",
  "Beauty and Cosmetics",
  "Books and Stationery",
  "Fashion and Apparel",
  "Food and Beverage",
  "Health and Wellness",
  "Home and Garden",
  "Industrial and Tools",
  "Jewelry and Accessories",
  "Pet Products",
  "Sports and Outdoors",
  "Technology and Electronics",
  "Toys and Entertainment",
  "Travel and Luggage",
];

export const zeroPad = (num) => String(num).padStart(2, "0");
