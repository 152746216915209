import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { mixed, object } from "yup";

const schema = object().shape(
  {
    myKad: mixed()
      .test("required", "MyKad is required", (value) => {
        return value && value.size;
      })
      // .test("fileSize", "The file is too large", (value, context) => {
      //   return value && value[0] && value[0].size <= 200000;
      // })
      .test("type", "Please upload pdf file", function (value) {
        return value && value.type === "application/pdf";
      }),
    borang9: mixed().when("borang13", {
      is: (value) => value && value.size,
      then: () =>
        mixed()
          .test("required", "Borang 9 is required", (value) => {
            return value && value.size;
          })
          // .test("fileSize", "The file is too large", (value, context) => {
          //   return value && value[0] && value[0].size <= 200000;
          // })
          .test("type", "Please upload pdf file", function (value) {
            return value && value.type === "application/pdf";
          }),
    }),
    borang13: mixed().when("borang9", {
      is: (value) => value && value.size,
      then: () =>
        mixed()
          .test("required", "Borang 13 is required", (value) => {
            return value && value.size;
          })
          // .test("fileSize", "The file is too large", (value, context) => {
          //   return value && value[0] && value[0].size <= 200000;
          // })
          .test("type", "Please upload pdf file", function (value) {
            return value && value.type === "application/pdf";
          }),
    }),
    letterOfAuthorization: mixed()
      .test("required", "Letter of Authorization is required", (value) => {
        return value && value.size;
      })
      // .test("fileSize", "The file is too large", (value, context) => {
      //   return value && value[0] && value[0].size <= 200000;
      // })
      .test("type", "Please upload pdf file", function (value) {
        return value && value.type === "application/pdf";
      }),
  },
  [["borang9", "borang13"]]
);

const PrivateLimitedDialog = (props) => {
  const { visible, onSubmit, onClose } = props;

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
  });

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
    }
  }, [visible]);

  const submit = async (formData) => {
    const params = new FormData();
    params.append("myKad", formData.myKad);
    params.append("borang9", formData.borang9);
    params.append("borang13", formData.borang13);
    params.append("letterOfAuthorization", formData.letterOfAuthorization);
    setSubmitting(true);
    await onSubmit(params);
    setSubmitting(false);
  };

  return (
    <Dialog open={visible} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Verify PRO</DialogTitle>
      <DialogContent>
        <Typography>Please submit the following documents to verify your PRO status:</Typography>

        <Grid container spacing={2} marginTop={2}>
          <Grid item xs={12}>
            <Typography>1. MyKad of Business owner, with front and back. (PDF Document)</Typography>
            <Controller
              name="myKad"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
                <TextField
                  type="file"
                  value={value?.fileName}
                  onChange={(event) => {
                    onChange(event.target.files[0]);
                  }}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>2. SSM Borang 9 (PDF Document)</Typography>
            <Controller
              name="borang9"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
                <TextField
                  type="file"
                  value={value?.fileName}
                  onChange={(event) => {
                    onChange(event.target.files[0]);
                  }}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography>3. SSM Borang 13 (PDF Document)</Typography>
            <Controller
              name="borang13"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
                <TextField
                  type="file"
                  value={value?.fileName}
                  onChange={(event) => {
                    onChange(event.target.files[0]);
                  }}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography>4. Letter of Authorization (PDF Document)</Typography>
            <Controller
              name="letterOfAuthorization"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
                <TextField
                  type="file"
                  value={value?.fileName}
                  onChange={(event) => {
                    onChange(event.target.files[0]);
                  }}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button variant="contained" color="inherit" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton variant="contained" color="primary" onClick={handleSubmit(submit)} loading={submitting}>
            Submit
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default PrivateLimitedDialog;
